import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import MuiAlert from "@mui/material/Alert";

// Function for Snackbar alert
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const isDevelopment = process.env.NODE_ENV === "development";

const InvoiceGeneration = () => {
  const [email, setEmail] = useState("");
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user, isAuthenticated } = useAuth0();

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const generateInvoiceEndpoint = isDevelopment
    ? process.env.REACT_APP_BACKEND_LOCAL + "/generate-invoice"
    : process.env.REACT_APP_BACKEND_GLOBAL + "/generate-invoice";

  const shopifyAddOrdersEndpoint = isDevelopment
    ? process.env.REACT_APP_BACKEND_LOCAL + "/add_orders"
    : process.env.REACT_APP_BACKEND_GLOBAL + "/add_orders";

  const handleGenerateInvoicesClick = async () => {
    setIsInvoiceLoading(true);
    try {
      const response = await axios.post(generateInvoiceEndpoint, {
        email: email,
      });
      if (response.status === 200) {
        setNotification(`Successfully generated invoice and sent to ${email}`);
        setSnackbarOpen(true);
      } else {
        console.error("Error generating invoices");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsInvoiceLoading(false);
    }
  };

  const handleAddOrdersClick = async () => {
    setIsOrderLoading(true);
    const params = new URLSearchParams();
    if (isAuthenticated && user) {
      params.append("email", user.email);
    }
    const handleOrdersEndpoint =
      shopifyAddOrdersEndpoint + "?" + params.toString();
    try {
      const res = await axios.post(handleOrdersEndpoint);
      console.log("Response in handleAddOrdersClick: ", res.data);
      console.log("Response status: ", res.data.status);
      if (res.data.status === "success") {
        setNotification("Successfully added order to Shopify");
        setSnackbarOpen(true);
      } else {
        throw new Error("Error adding orders.");
      }
    } catch (err) {
      setNotification(err.message);
    } finally {
      setIsOrderLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        bgcolor: "#161328", // Set this to the desired background color
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "500px",
          p: 8,
          bgcolor: "#373548",
          borderRadius: 1,
          boxShadow: 2,
        }}
      >
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{
            color: "white",
            fontWeight: "bold",
            marginBottom: "16px",
            "& .MuiInputBase-input": {
              color: "white",
            },
            "& .MuiInputLabel-root": {
              color: "white",
            },
          }}
          helperText={!isValidEmail(email) && "Please enter a valid email."}
          error={!isValidEmail(email)}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={
            isValidEmail(email) ? handleGenerateInvoicesClick : undefined
          }
          disabled={isInvoiceLoading || !isValidEmail(email)}
          sx={{
            backgroundColor: "#62BD88",
            mt: 2,
            color: "white",
          }}
        >
          {isInvoiceLoading ? (
            <CircularProgress size={24} />
          ) : (
            "Generate Invoice For Instagram Conversations"
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddOrdersClick}
          disabled={isOrderLoading}
          sx={{
            backgroundColor: "#62BD88",
            mt: 2,
            color: "white",
          }}
        >
          {isOrderLoading ? (
            <CircularProgress size={24} />
          ) : (
            "Add Orders to Shopify"
          )}
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <div>
          <Alert onClose={handleCloseSnackbar} severity="success">
            {notification}
          </Alert>
        </div>
      </Snackbar>
    </Box>
  );
};

export default InvoiceGeneration;
