import React, { useState, useEffect } from "react";
import { Button, Box, TextField } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const isDevelopment = process.env.NODE_ENV === "development";
const instagramAuthRedirectUri = isDevelopment
  ? process.env.REACT_APP_FRONTEND_LOCAL
  : process.env.REACT_APP_FRONTEND_GLOBAL;

const shopifyCallbackEndpoint = isDevelopment
  ? process.env.REACT_APP_BACKEND_LOCAL + "/shopify/callback"
  : process.env.REACT_APP_BACKEND_GLOBAL + "/shopify/callback";

const upsertUserEndpoint = isDevelopment
  ? process.env.REACT_APP_BACKEND_LOCAL + "/upsert-user"
  : process.env.REACT_APP_BACKEND_GLOBAL + "/upsert-user";

const Authentication = () => {
  const [isInstagramConnected, setIsInstagramConnected] = useState(false);
  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [notification, setNotification] = useState("");
  const [shopUrl, setShopUrl] = useState(
    process.env.REACT_APP_TEST_SHOPIFY_STORE  // TODO: Verify if shop url can be inferred from shopify auth
  );
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const userEmail = user?.email || "";
  const navigate = useNavigate();

  const handleContinueClick = () => {
    if (isInstagramConnected && isShopifyConnected) {
      navigate("/invoice-generation");
    }
  };

  const handleInstagramAuth = () => {
    const clientId = process.env.REACT_APP_INSTAGRAM_CLIENT_ID;
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${instagramAuthRedirectUri}&scope=user_profile,user_media&response_type=code`;
    console.log('Instagram auth url: ', authUrl)
    window.location.href = authUrl;
  };

  const handleShopifyOAuth = async () => {
    if (!shopUrl || !/^[\w-]+\.myshopify\.com$/.test(shopUrl)) {
      setNotification("Invalid shop URL");
      return;
    }

    const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;

    // Generate a unique state parameter
    const state = uuidv4();

    const response = await axios.post(upsertUserEndpoint, {
      state,
      email: userEmail,
    });

    if (response.status !== 200) {
      console.error("Failed to save state and email");
      return;
    }

    const authUrl = `https://${shopUrl}/admin/oauth/authorize?client_id=${clientId}&redirect_uri=${shopifyCallbackEndpoint}&scope=read_products,write_products,write_orders&response_type=code&state=${state}`;
    // Open the authorization URL in a new window or tab
    // window.open(authUrl, "_blank");

    window.location.href = authUrl;
  };

  // Checks if there's an Instagram code in the URL. If this is true, it logs the Instagram code and sets isConnected state to true. This indicates that the user has successfully authenticated with Instagram.
  const checkInstagramConnection = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code"); // TODO: The user can hack this by simply adding "code" into the url. How do we verify the code with Instagram?
    if (code) {
      localStorage.setItem("instagramConnected", "true"); // Store the connected state in localStorage
    }
  };

  useEffect(() => {
    checkInstagramConnection();
    const instagramConnected = localStorage.getItem("instagramConnected");
    if (instagramConnected) {
      setIsInstagramConnected(true);
      console.log("Is instagram connected?", instagramConnected);
    }
  }, []);

  const checkShopifyConnection = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shopifyToken = urlParams.get("shopify_access_token");

    if (shopifyToken) {
      console.log("Shopify access token:", shopifyToken);
      localStorage.setItem("shopifyConnected", "true"); // Store the connected state in localStorage
    }
  };

  useEffect(() => {
    checkShopifyConnection();
    const shopifyConnected = localStorage.getItem("shopifyConnected"); // Retrieve the connected state from localStorage
    if (shopifyConnected === "true") {
      setIsShopifyConnected(true);
      console.log("Is shopify connected?", shopifyConnected);

    }
  }, []);

  // Checks if the user is not authenticated and if there is no Instagram code in the URL. If this is true, it redirects the user to log in with Auth0.
  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get("code");

    if (!isAuthenticated) {// && !code) {
      loginWithRedirect();
    }
    console.log('Is authenticated: ', isAuthenticated);
    console.log('User email: ', userEmail);
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "#161328",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          bgcolor: "#161328",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto", // adjust this as per your requirement
            marginBottom: "48px", // Add margin bottom for spacing
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleInstagramAuth}
            disabled={isInstagramConnected}
            sx={{
              backgroundColor: "#62BD88",
              color: "white",
              "&.Mui-disabled": {
                opacity: 0.3,
                backgroundColor: "#62BD88",
                color: "white",
              },
            }}
          >
            Connect your Instagram
          </Button>
          {isInstagramConnected ? (
            <CheckCircleOutlineIcon sx={{ color: "#62BD88", ml: 1 }} />
          ) : (
            <Box sx={{ width: "24px" }} /> // Placeholder to keep the space when icon is not there, adjust width to match the icon size
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "auto", // adjust this as per your requirement
            flexDirection: "column", // Added this line to set the direction to column
            marginTop: "16px", // Add margin bottom for spacing
          }}
        >
          <TextField
            label="Shopify Shop URL"
            value={shopUrl}
            onChange={(e) => setShopUrl(e.target.value)}
            fullWidth
            sx={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "16px",
              "& .MuiInputBase-input": {
                color: "white",
              },
              "& .MuiInputLabel-root": {
                color: "white",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%", // Adjust this width to match the desired layout
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleShopifyOAuth}
              disabled={isShopifyConnected}
              sx={{
                backgroundColor: "#62BD88",
                color: "white",
                "&.Mui-disabled": {
                  opacity: 0.3,
                  backgroundColor: "#62BD88",
                  color: "white",
                },
              }}
            >
              Connect your Shopify
            </Button>
            {isShopifyConnected ? (
              <CheckCircleOutlineIcon sx={{ color: "#62BD88", ml: 1 }} />
            ) : (
              <Box sx={{ width: "24px" }} /> // Placeholder to keep the space when icon is not there, adjust width to match the icon size
            )}
          </Box>
        </Box>
        {isInstagramConnected && isShopifyConnected && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinueClick}
            sx={{
              backgroundColor: "#62BD88",
              mt: 2,
              color: "white",
            }}
          >
            Continue
          </Button>
        )}
      </Box>
      {notification && (
        <Box
          sx={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            mt: 2,
          }}
        >
          {notification}{" "}
        </Box>
      )}{" "}
    </Box>
  );
};

export default Authentication;
