import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function Auth0CallbackHandler() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');  // redirect to home page
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>Loading...</div>  // display a loading message while waiting for authentication
  );
}

export default Auth0CallbackHandler;
