import React from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Auth0CallbackHandler from "./components/Auth0CallbackHandler";
import Authentication from "./components/Authentication";
import InvoiceGeneration from "./components/InvoiceGeneration";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Authentication />} />
          <Route path="/invoice-generation" element={<InvoiceGeneration />} />
          <Route path="/auth0_callback" element={<Auth0CallbackHandler />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
